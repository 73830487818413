import React, { Component } from 'react'

export default class PageHeaderQuote extends Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div class="boxa">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p className="quote has-text-centered">
                    "{this.props.quote}"
                    <br/>
                    -<strong>{this.props.author}</strong>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      // <div className="container">
      //   <div className="section">
      //     <h2 className="title is-2 has-text-grey-dark has-text-weight-light has-text-centered is-uppercase">
      //       {this.props.quote}
      //     </h2>
      //     <h3 className="subtitle has-text-centered has-text-grey">{this.props.author}</h3>
      //   </div>
      // </div>
    )
  }
}

import React from 'react'

const socialStyle = {
  marginRight: '20px'
};
const Footer = class extends React.Component {

  componentDidMount() {
    
   
  }
 
 render() {
   return (
    <footer className="footer">
      <div className="container">
        <div className="content columns">
          <div className="column">
            <p>
              Copyright 2019. 
              &nbsp;
              <a href="https://sumofoneorg.com">
                <strong className="has-text-grey-dark">Sum of one</strong> 
              </a>
            </p>
          </div>
          <div className="column has-text-right">
            <a href="https://www.facebook.com/sumofoneorg/" style={socialStyle}>
              <span className="icon has-text-grey-dark">
                <i className="fab fa-facebook fa-2x"></i>
              </span>
            </a>
            <a href="https://www.instagram.com/sumofoneorg/">
              <span className="icon has-text-grey-dark">
                <i className="fab fa-instagram fa-2x"></i>
              </span>
            </a>
          </div>
        </div>

      </div>
    </footer>
  
  )}
}

export default Footer
